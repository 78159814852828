// Footer
.footer-comp {
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  min-height: 60px;
  padding: 10px 0px;
  .wrap {
  }
}

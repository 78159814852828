.lading-page {
  padding: 20px;
  // width: 100%;
  // height: 100%;

  // @media only screen and (max-width: 640px) {
  //   .wrap{
  //     width: 350px !important;
  //   }
  // }

  .wrap {
    // width: 100%;

    .token-box {
      // width: 100%;
      min-height: 200px;
      padding: 50px;
      background: rgb(8, 52, 104);
      background: linear-gradient(117deg,
          rgba(8, 52, 104, 1) 5%,
          rgba(15, 28, 44, 1) 100%);

      // border: 1px solid $themeColor2;
      // box-shadow: 0px 0px 15px 5px #00000040;
      @include media("<=tablet") {
        padding: 20px;
      }

      .timer-comp {
        width: 100%;
      }

      .timer-box {
        width: 100%;
        justify-content: center;
      }

      .timer-comp .time-item {
        padding-top: 4px;
        display: flex;
        justify-content: center;
        justify-items:center;
      }

      .timer-comp .time-item ._vale {
        color: #fff;
        font-size: 35px;
        font-weight: 600;
      }

      .timer-comp .time-item ._tag {
        color: #000;
        font-size: 14px;
      }

      .progress-bar {
        width: 100%;
        height: 16px;
        border-radius: 20px;
        background: #374456;

        .inner-bar {
          height: 100%;
          background: linear-gradient(90deg, #0da6f9 0%, #21ffdb 100%);
        }
      }
    }
  }
}


@media only screen and (max-width: 549px) {
  .lading-page {
    padding: 0px;
  }
}

.choose-wallet-modal {
  width: 350px;
  min-height: 400px;
  padding: 20px;
  background: rgb(8, 52, 104);
  background: linear-gradient(117deg,
      rgba(8, 52, 104, 1) 5%,
      rgba(15, 28, 44, 1) 100%);
}